import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Muelheim: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Muelheim,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Mülheim"
          d="M683,438l37,52l-24,16l10,12l-22,50l-36,4l-10,20h-10l-18-6l-16,18l-10-8l14-10l-8-22l2-2.2 c4.3-4.5,10-8.3,15.8-10.4c9-3.3,13.4-7,19.6-11.9l0,0c3-2.3,5.4-5.3,7.1-8.6v0c3.6-7.2,6.3-14.9,8.1-22.7l0,0 c1.8-7.8,1.9-16,0.2-23.9c-0.5-2-1.2-3.1-1.5-4.5l7.7-3.8l4-14l-8-22l4-2l12,12l2-12l10-2v6L683,438z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 654.1327 490.1292)"
        >
          Mülheim
        </text>
      </g>
    </>
  );
});
